import React from 'react';
import '../App.css';

function AurisID() {
  return (
    <div className='container-fluid pt-3'>
      <div className="container  verlag-bold pt-1">
        <h1 style={{ color: '#8a8a8d' }} className="text-center"><i style={{ color: '#5eaed5' }}>Auris </i>ID</h1>
        <p className="verlag-light">
          <p className="text-center">
            Real-Time PCR kit for the detection of Candida auris <br />
          </p>
        </p>
        <br />
        <div className="text-center verlag-black">
          <button
            type="button"
            style={{width: '30%'}}
            className="btn btn-outline-info btn-block"
            onClick={() => window.location.href = '/contact'}
          >
            CONTACT US
          </button>
        </div> <br/>
        <div className="container-olm">
          <p>
            Product Code: OLM2010
          </p>
          <p className="verlag-light">
            <b>Specimens:</b> Serum, Plasma, Surveillance swabs (axilla/groin, nasopharyngeal) <br />
            50 reactions per kit
          </p>
          <p className="verlag-light">
            <b>Targets</b> <br />
            • Candida auris <br />
            • Internal Extraction Control (IEC) <br /><br />

            <b>Features and benefits</b> <br />
            • Culture-independent diagnostics <br />
            • Compatible with existing laboratory equipment <br />
            • Results within 45 minutes of nucleic acid extraction from serum/plasma <br />
            • Direct detection from clinical surveillance samples (without prior DNA extraction) <br />
            • ‘Ready to use’ reagents – no resuspension/dilution steps required <br />
            • Enabling quick laboratory reporting to support clinical decision-making when time is of the essence <br /><br />

            <b>Kit Contents</b> <br />
            • Primer/Probe Mix <br />
            • qPCR Master Mix <br />
            • RNase/DNase-free water <br />
            • Positive Control <br />
            • Internal Extraction Control (IEC) <br /><br />


            <b>Performance Characteristics </b> <br />
            • Sensitive to 	&lt;10 Candida auris genome copies <br />
            • Proven efficacy in clinical decision making for infection control and patient management <br /><br />


            <b>Background</b> <br />
            Over the past decade, Candida auris has emerged as a worldwide public health threat.
            Since its first isolation in 2009 in Japan, C. auris infections have been reported in over 30 countries.
            Together with the increasing number of infections, this fungus is a cause of concern for three main reasons.
            First, C. auris easily proliferates in hospitals and has the potential to cause outbreaks in healthcare
            settings.
            Secondly, it is often resistant to multiple antifungal drugs commonly used to treat Candida infections, such
            as Fluconazole,
            and thirdly, it is often challenging to identify with standard laboratory methods. <br /><br />

            C. auris has been isolated from various clinical specimens,
            including normally sterile body fluids, respiratory sections, ear canal,
            biliary fluid, urine, wounds and mucocutaneous swabs. Bloodstream infections (BSI)
            are the most commonly observed invasive infections, and, alarmingly, the mortality
            rates reported are in the range of 30-60%. BSI are particularly dangerous in
            immunocompromised and critically ill patients, with the highest risk being for
            patients with breathing tubes, feeding tubes and central venous catheters. <br /><br />

            C. auris can spread in healthcare settings through contact with contaminated environmental surfaces or
            equipment, or from person to person, and implications of outbreaks can be dramatic (e.g., closing entire
            hospital departments). Therefore, surveillance and screening for C. auris colonization are essential to
            implement infection prevention. <br /><br />


          </p> <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default AurisID;
