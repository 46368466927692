import React from 'react';
import '../App.css';

function AspID() {
  return (
    <div className='container-fluid pt-3'>
      <div className="container  verlag-bold pt-1">
        <h1 style={{ color: '#8a8a8d' }} className="text-center"><i style={{ color: '#5f285e' }}>Asp </i>ID</h1>
        <p className="verlag-light">
          <p className="text-center">
            Real-Time PCR kit for the detection of clinically relevant Aspergillus species <br />
          </p>
        </p>
        <br />
        <div className="text-center verlag-black">
          <button
            type="button"
            style={{width: '30%'}}
            className="btn btn-outline-info btn-block"
            onClick={() => window.location.href = '/contact'}
          >
            CONTACT US
          </button>
        </div> <br/>
        <div className="container-olm">
          <p>
            Product Code: OLM2006
          </p>
          <p className="verlag-light">
            <b>Specimens:</b> Serum, Plasma, BAL <br />
            50 reactions per kit
          </p>
          <p className="verlag-light">
            <b>Targets</b> <br />
            • Aspergillus species <br />
            • Aspergillus terreus <br />
            • Internal Extraction Control (IEC) <br /><br />


            <b>Features and benefits</b> <br />
            • Aids in the diagnosis of Invasive Aspergillosis (IA) <br />
            • Differentiation of A. terreus and Aspergillus species <br />
            • Culture-independent diagnostics <br />
            • Results within 90 minutes of nucleic acid extraction <br />
            • ‘Ready to use’ reagents – no resuspension/dilution steps required <br />
            • Compatible with existing laboratory equipment <br />
            • Enabling quick laboratory reporting to support clinical decision-making when time is of the essence <br /><br />


            <b>Kit Contents</b> <br />
            • Primer/Probe Mix <br />
            • qPCR Master Mix <br />
            • RNase/DNase-free water <br />
            • Positive Controls <br />
            • Internal Extraction Control (IEC) <br /><br />


            <b>Performance Characteristics </b> <br />
            • Sensitive to 	&lt;10 copies of Aspergillus target template (equivalent to &lt;1 fungal genome) <br />
            • Proven efficacy in the diagnosis of IA <br />


            <b>Background</b> <br />
            Aspergillosis is an infection caused by Aspergillus, a common fungus that grows indoors and outdoors.
            Most people breathe in Aspergillus spores daily without becoming unwell. However, in persons whose
            immune systems have been compromised by cancer treatment, bone marrow transplantation, or other immune-related condition,
            aspergillosis can quickly become invasive pulmonary aspergillosis (IPA or IA). This is the most severe type of aspergillosis,
            and it happens when the infection spreads quickly from the lungs to the brain, heart, kidneys, or skin.
            The most common cause of IPA is A. fumigatus, followed by A. flavus and A. terreus. If left untreated, invasive aspergillosis can be lethal. <br /><br />
          </p>
          <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default AspID;
