import React, {} from "react";
import './App.css';
import Home from './components/Home'
import Contact from './components/Contact'
import Nav from './components/Nav';
import Footer from './components/Footer';
import About from './components/About';
import Careers from './components/Careers';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import {getASP, getCrag, getCragSQ, getCTA, getHGM, getCRY, getCab, getBta, getAgm, getLatex, getCF} from './productConfigs';
import MycoDDR from './components/MycoDDR';
import Id_Kits from './components/Id_Kits';
import NotFound from './components/NotFound';
import CandidaAuris from './components/OLM_CandID'
import AurisID from './components/OLM_AurisID'
import PneumID from './components/OLM_PneumID'
import AspID from './components/OLM_AspID'


function App() {

  // If you need to add or edit a Product, navigate to the productConfigs.js file.

  return (
      <Router className="App">
      <Nav/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/careers" element={<Careers/>}/>
          <Route path="/candida-auris" element={<CandidaAuris/>}/>
          <Route path="/candida-species" element={<AurisID/>}/>
          <Route path="/aspergillus" element={<AspID/>}/>
          <Route path="/pneumocystis-jirovecii" element={<PneumID/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/aspergillus-gm-lateral-flow-assay-fungal-test" element={getASP()}/>
          <Route path="/crag-cryptococcal-antigen-lateral-flow-assay-fungal-test" element={getCrag()}/>
          <Route path="/cragsq" element={getCragSQ()}/>
          <Route path="/coccidioides-antibody-lateral-flow-assay-fungal-test" element={getCTA()}/>
          <Route path="/histoplasma-gm-enzyme-immunoassay-fungal-test" element={getHGM()}/>
          <Route path="/cryptococcal-antigen-enzyme-immunoassay-fungal-test" element={getCRY()}/>
          <Route path="/coccidioides-ab-enzyme-immunoassay-fungal-test" element={getCab()}/>
          <Route path="/blastomyces-ab-enzyme-immunoassay-fungal-test" element={getBta()}/>
          <Route path="/aspergillus-gm-enzyme-immunoassay-fungal-test" element={getAgm()}/>
          <Route path="/latex-agglutination" element={getLatex()}/>
          <Route path="/complement-fixation-antigens-controls" element={getCF()}/>
          <Route path="/mycoddr-AFB-processing" element={<MycoDDR etq={['PIS-00123','PIS-00188','PIS-00184','PIS-00185','PIS-00094','PIS-00205','PIS-00204','PIS-00186','PIS-00187','PIS-00208','PIS-00096','PIS-00201','PIS-00095','PIS-00200','PIS-00202','PIS-00097','PIS-00203']}/>}/>
          <Route path="/immunodiffusion-kits-and-reagents" element={<Id_Kits etq={['PIS-00142','PIS-00143','PIS-00144','PIS-00145','PIS-00221','PIS-00080','PIS-00198','PIS-00172','PIS-00173','PIS-00091','PIS-00197','PIS-00162','PIS-00163','PIS-00164','PIS-00165','PIS-00166','PIS-00167','PIS-00124']}/>}/>
          <Route path="/asp" element={getASP()}/>
          <Route path="/crag" element={getCrag()}/>
          <Route path="/cragsq" element={getCragSQ()}/>
          <Route path="/cta" element={getCTA()}/>
          <Route path="/hgm" element={getHGM()}/>
          <Route path="/cry" element={getCRY()}/>
          <Route path="/cab" element={getCab()}/>
          <Route path="/blasto" element={getBta()}/>
          <Route path="/agm101" element={getAgm()}/>
          <Route path="/latex" element={getLatex()}/>
          <Route path="/cfr" element={getCF()}/>
          <Route path="/mycoddr" element={<MycoDDR etq={['PIS-00123','PIS-00188','PIS-00184','PIS-00185','PIS-00094','PIS-00205','PIS-00204','PIS-00186','PIS-00187','PIS-00208','PIS-00096','PIS-00201','PIS-00095','PIS-00200','PIS-00202','PIS-00097','PIS-00203']}/>}/>
          <Route path="/immunodiffusion" element={<Id_Kits etq={['PIS-00142','PIS-00143','PIS-00144','PIS-00145','PIS-00221','PIS-00080','PIS-00198','PIS-00172','PIS-00173','PIS-00091','PIS-00197','PIS-00162','PIS-00163','PIS-00164','PIS-00165','PIS-00166','PIS-00167','PIS-00124']}/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      <Footer/>
      </Router>
  );
}

export default App;
