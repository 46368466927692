import React from 'react';

function Crag_Relevancy() {
  return (
    <div>
      <div className='col-12 verlag-light pt-2 text-center'>
        <h1 className='verlag-bold'>5 Easy Steps</h1>
        <img className='col-12 my-2' src='/images/CrAg_5steps.jpg' alt='5 Easy Steps' />
      </div>
      <hr width='100%' />
      <div className='col-12 text-center pb-4'>
        <h1 className='verlag-bold'>
          Clinical Relevancy
          <sup>
            <small>2,3,4</small>
          </sup>
        </h1>
      </div>
      <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center'>
        <div className='col-12 col-sm-12 col-md-10 col-lg-5 m-0'>
          <table
            className='table table-striped table-bordered table-info pb-0 mb-0 my-auto verlag-black table-responsive text-center align-middle w-auto border-left-0 border-top-0'
            style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
          >
            <thead>
              <tr className='border-top-0'>
                <th
                  scope='col'
                  className='h2 align-middle table_header'
                  style={{
                    backgroundColor: 'white !important',
                    borderTopColor: 'white !important',
                    borderLeftColor: 'white !important',
                  }}
                >
                  SERUM{' '}
                </th>
                <th scope='col' className='h5 align-middle'>
                  Positive % Agreement{' '}
                </th>
                <th scope='col' className='h5 align-middle'>
                  Negative % Agreement
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row' className='h5 align-middle'>
                  CrAg EIA
                </th>
                <td className='h4 align-middle'>100%</td>
                <td className='h4 align-middle'>93%</td>
              </tr>
            </tbody>
          </table>
          <small className='text-left'>2) IMMY CrAg LFA US IFU</small>
          <br />
          <small className='text-left'>3) IMMY CrAg LFA International IFU</small>
        </div>

        <div className='col-12  col-sm-12 col-md-10 col-lg-7 m-0'>
          <table
            className='table table-striped table-bordered table-danger pb-0 mb-0 my-auto verlag-black table-responsive text-center align-middle w-auto border-left-0 border-top-0'
            style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
          >
            <thead>
              <tr className='border-top-0'>
                <th
                  scope='col'
                  className='h2 align-middle table_header'
                  style={{
                    backgroundColor: 'white !important',
                    borderTopColor: 'white !important',
                    borderLeftColor: 'white !important',
                  }}
                >
                  SERUM{' '}
                </th>
                <th scope='col' className='h5 align-middle' width='25%'>
                  Positive % Agreement{' '}
                </th>
                <th scope='col' className='h5 align-middle' width='25%'>
                  Negative % Agreement{' '}
                </th>
                <th scope='col' className='h5 align-middle'>
                  Total % Agreement
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row' className='h5 align-middle'>
                  Meridian LA
                </th>
                <td className='h4 align-middle'>100%</td>
                <td className='h4 align-middle'>100%</td>
                <td className='h4 align-middle'>99.8%</td>
              </tr>
            </tbody>
          </table>
          <small className='text-left'>
            4) Binnicker MJ, et al. Comparison of Four Assays for the Detection of Cryptococcal
            Antigen. Clin and Vacc Immuno. 2012; 19(18):1988.{' '}
          </small>
        </div>
      </div>
      <div className='col-12 pt-2'>
        <p className='verlag-light'>
          When tested against Meridian’s EIA, the CrAg LFA showed high diagnostic performance in 197
          serum specimens. The Crag LFA agreed with <b>100%</b> of the Meridian EIA positive test
          results, and <b>93%</b> of the Meridian EIA negative test results.<sup>2,3</sup>
        </p>
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-12 pt-5'>
        <table
          className='table table-striped table-bordered table-success pb-0 mb-0 my-auto verlag-black text-center align-middle w-auto table-responsive border-top-0 border-left-0'
          style={{ borderLeftColor: 'white !important', borderTopColor: 'white !important' }}
        >
          <thead>
            <tr className='border-top-0'>
              <th
                scope='col'
                className='h2 align-middle table_header'
                style={{
                  backgroundColor: 'white !important',
                  borderTopColor: 'white !important',
                  borderLeftColor: 'white !important',
                }}
              >
                Culture/India Ink
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                SERUM<sup>2,3</sup>
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                CSF<sup>2,3</sup>
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                PLASMA<sup>2</sup>
              </th>
              <th scope='col' className='h5 align-middle' width='25%'>
                WHOLE BLOOD<sup>2</sup>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Sensitivity{' '}
              </th>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>98.9%</td>
              <td className='h4 align-middle'>99.3%</td>
            </tr>
            <tr>
              <th scope='row' className='h5 align-middle'>
                Specificity
              </th>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>100%</td>
              <td className='h4 align-middle'>94.4%</td>
            </tr>
          </tbody>
        </table>
        <small className='text-left'>2) IMMY CrAg LFA Domestic IFU</small>
        <br />
        <small className='text-left'>3) IMMY CrAg LFA International IFU</small>
      </div>
      <div className='col-12 pt-2 verlag-light'>
        <p>
          When tested against culture and India ink, the CrAg LFA showed high diagnostic performance
          in all specimen types.
        </p>
        <p>
          From the table above, we can conclude that the CrAg LFA will correctly generate a positive
          result for <b>100%</b> of patients with Cryptococcus in serum and CSF samples, and{' '}
          <b>98%</b> in Plasma and Whole Blood samples.<sup>2,3</sup>
        </p>
        <p>
          The CrAg LFA will correctly generate a negative result for <b>100%</b> of patients without
          Cryptococcus in serum, CSF, and Plasma samples, and <b>94%</b> in Whole Blood samples.
          <sup>2,3</sup>
        </p>
      </div>
    </div>
  );
}

export default Crag_Relevancy;
