import React from 'react';
import '../App.css';

function PneumID() {
  return (
    <div className='container-fluid pt-3'>
      <div className="container  verlag-bold pt-1">
        <h1 style={{ color: '#8a8a8d' }} className="text-center"><i style={{ color: '#f278c8' }}>Pneum </i>ID</h1>
        <p className="verlag-light">
          <p className="text-center">
            Real-Time PCR kit for the detection of Pneumocystis jirovecii <br />
          </p>
        </p>
        <br />
        <div className="text-center verlag-black">
          <button
            type="button"
            style={{width: '30%'}}
            className="btn btn-outline-info btn-block"
            onClick={() => window.location.href = '/contact'}
          >
            CONTACT US
          </button>
        </div> <br/>
        <div className="container-olm">
          <p>
            Product Code: OLM2008
          </p>
          <p className="verlag-light">
            <b>Specimens:</b> BAL, Respiratory Wash <br />
            50 reactions per kit
          </p>
          <p className="verlag-light">
            <b>Targets</b> <br />
            • Pneumocystis jirovecii <br />
            • Human β-globin gene (Endogenous Internal Control) <br />
            • Internal Extraction Control (IEC) <br /><br />

            <b>Features and benefits</b> <br />
            • Aids in the diagnosis of Pneumocystis Pneumonia
            • Human β-globin gene detection is included as an endogenous internal control for the assessment of specimen
            quality and nucleic acid extraction efficiency <br />
            • Culture-independent diagnostics <br />
            • Results within 45 minutes of nucleic acid extraction <br />
            • ‘Ready to use’ reagents – no resuspension/dilution steps required <br />
            • Compatible with existing laboratory equipment <br />
            • Enabling quick laboratory reporting to support clinical decision-making when time is of the essence <br /><br />


            <b>Kit Contents</b> <br />
            • Primer/Probe Mix <br />
            • qPCR Master Mix <br />
            • RNase/DNase-free water <br />
            • Positive Control <br />
            • Internal Extraction Control (IEC) <br /><br />


            <b>Performance Characteristics </b> <br />
            • Sensitive to 	&lt;1 Pneumocystis genome copy <br />
            • Proven efficacy in the diagnosis of PCP <br /><br />


            <b>Background</b> <br />
            Pneumocystis jirovecii pneumonia (PCP) is a life-threatening opportunistic disease caused by Pneumocystis jirovecii,
            formerly known as Pneumocystis carinii species, a unicellular eukaryotic and ubiquitous yeast-like fungus.
            Conservative approximations calculate around 400,000 cases of PCP annually worldwide, but this is likely to be an
            underestimate of the true burden. It is estimated that up to 20% of adults might carry this fungus at any given time,
            without any symptoms, resulting in continuous interindividual circulation responsible for transmission to immunocompromised
            individuals who are at high risk of developing PCP. Amongst them, around 30-40% of PCP infections occur in individuals with human
            immunodeficiency virus (HIV) infection/acquired immune deficiency syndrome (AIDS). In addition to HIV coinfection, other risk factors
            include chronic lung diseases, cancer, inflammatory or autoimmune diseases and solid organ or stem cell transplant.
            Once PCP is clinically suspected, the diagnosis should be time-sensitive, and treatment should be initiated as soon as possible
            to avoid further complications. <br /><br />


          </p> <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default PneumID;
