import React, {useState, useEffect, useRef} from 'react'

import axios from 'axios';
import AutoComplete from './Autocomplete';
import ReCAPTCHA from "react-google-recaptcha"
import Swal from 'sweetalert2';
function Contact() {



  const [submitted, setSubmitted] = useState(0);
  const [imgFile, setImgFile] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [cragChecked, setCragChecked] = useState(false);
  const [aspChecked, setAspChecked] = useState(false);
  const [cocciLFAChecked, setCocciLFAChecked] = useState(false);
  const [histoChecked, setHistoChecked] = useState(false);
  const [cryptoAntigenChecked, setCryptoAntigenChecked] = useState(false);
  const [cocciAntibodyChecked, setCocciAntibodyChecked] = useState(false);
  const [blastoAntibodyChecked, setBlastoAntibodyChecked] = useState(false);
  const [aspEIAChecked, setAspEIAChecked] = useState(false);
  const [latexChecked, setLatexChecked] = useState(false);
  const [mycoDDRChecked, setMycoDDRChecked] = useState(false);
  const [immunoReagentsChecked, setImmunoReagentsChecked] = useState(false);
  const [complementChecked, setComplementChecked] = useState(false);
  const [otherChecked, setOtherChecked] = useState(false);
  const [otherProducts, setOtherProducts] = useState("");
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Title: "",
    Company: "",
    Email: "",
    Phone: "",
    ProductInterest: new Map([
      ["CrAg", { checked: 0, Name: "Cryptococcal Antigen (CrAg LFA)" }],
      ["Asp", { checked: 0, Name: "Aspergillus Galactomannan" }],
      ["Cocci LFA", { checked: 0, Name: "Coccidioides Antibody" }],
      ["Histo", { checked: 0, Name: "Histoplasma Galactomannan" }],
      ["Crypto Antigen", { checked: 0, Name: "Cryptococcal Antigen" }],
      ["Cocci Antibody", { checked: 0, Name: "Coccidioides Antibody" }],
      ["Blasto Antibody", { checked: 0, Name: "Blastomyces Antibody" }],
      ["Aspergillus EIA", { checked: 0, Name: "Aspergillus Galactomannan (EIA)" }],
      ["Latex", { checked: 0, Name: "Latex Agglutination" }],
      ["MycoDDR", { checked: 0, Name: "MycoDDR" }],
      ["Immuno Reagents/Plates", { checked: 0, Name: "Immunodiffusion Reagents and Plates" }],
      ["Complement Fixation Reagents", { checked: 0, Name: "Complement Fixation Reagents" }],
    ]),
    Product_Interest__c: "",
    Other_Product_Interest__c: "",
    Notes__c: "",
    Campaign__c: ""
  });
  const [location, setLocation] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const reCaptchaRef = useRef();

  useEffect(() => {
    if (submitted === 1) {
      postSalesForceData();
      setSubmitted(0);
    }
  }, [formData]);

  useEffect(() => {
    if (submitted === 1) onSubmit();
  }, [submitted]);


  const productChange = (prod, checked) => {
    if (formData.ProductInterest.get(prod)?.checked && prod !== "Other") {
      formData.ProductInterest.set(prod, {
        ...formData.ProductInterest.get(prod),
        checked: 0,
      });
      updateCheckBox(prod, false)
    } else if (!formData.ProductInterest.get(prod)?.checked && prod !== "Other") {
      formData.ProductInterest.set(prod, {
        ...formData.ProductInterest.get(prod),
        checked: 1,
      });
      updateCheckBox(prod, true)
    } else if(prod === "Other"){
      updateCheckBox(prod, !otherChecked)
    }
  };

  const updateCheckBox = (prod, bool) => {
    if(prod === 'CrAg'){
      setCragChecked(bool)
    } else if(prod === 'Asp'){
      setAspChecked(bool)
    } else if(prod === 'Cocci LFA'){
      setCocciLFAChecked(bool)
    } else if(prod === 'Histo'){
      setHistoChecked(bool)
    } else if(prod === 'Crypto Antigen'){
      setCryptoAntigenChecked(bool)
    } else if(prod === 'Cocci Antibody'){
      setCocciAntibodyChecked(bool)
    } else if(prod === 'Blasto Antibody'){
      setBlastoAntibodyChecked(bool)
    } else if(prod === 'Aspergillus EIA'){
      setAspEIAChecked(bool)
    } else if(prod === 'Latex'){
      setLatexChecked(bool)
    } else if(prod === 'MycoDDR'){
      setMycoDDRChecked(bool)
    } else if(prod === 'Immuno Reagents/Plates'){
      setImmunoReagentsChecked(bool)
    } else if(prod === 'Complement Fixation Reagents'){
      setComplementChecked(bool)
    } else if(prod === "Other"){
      setOtherChecked(bool)
    }
  }
  //clears all state variables to prep for next entry
  const clearState = () => {
    setFormData({
      FirstName: "",
      LastName: "",
      Title: "",
      Company: "",
      Email: "",
      Phone: "",
      ProductInterest: new Map([
        ["CrAg", { checked: 0, Name: "Cryptococcal Antigen (CrAg LFA)" }],
        ["Asp", { checked: 0, Name: "Aspergillus Galactomannan" }],
        ["Cocci LFA", { checked: 0, Name: "Coccidioides Antibody" }],
        ["Histo", { checked: 0, Name: "Histoplasma Galactomannan" }],
        ["Crypto Antigen", { checked: 0, Name: "Cryptococcal Antigen" }],
        ["Cocci Antibody", { checked: 0, Name: "Coccidioides Antibody" }],
        ["Blasto Antibody", { checked: 0, Name: "Blastomyces Antibody" }],
        ["Aspergillus EIA", { checked: 0, Name: "Aspergillus Galactomannan (EIA)" }],
        ["Latex", { checked: 0, Name: "Latex Agglutination" }],
        ["MycoDDR", { checked: 0, Name: "MycoDDR" }],
        ["Immuno Reagents/Plates", { checked: 0, Name: "Immunodiffusion Reagents and Plates" }],
        ["Complement Fixation Reagents", { checked: 0, Name: "Complement Fixation Reagents" }],
      ]),
      Product_Interest__c: "",
      Other_Product_Interest__c: "",
      Notes__c: "",
    });
    setImgFile(null);

    setLocation({
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    });
    setSubmitted(0);
    setCragChecked(false);
    setAspChecked(false);
    setCocciLFAChecked(false);
    setHistoChecked(false);
    setCryptoAntigenChecked(false);
    setCocciAntibodyChecked(false);
    setBlastoAntibodyChecked(false);
    setAspEIAChecked(false);
    setLatexChecked(false);
    setMycoDDRChecked(false);
    setImmunoReagentsChecked(false);
    setComplementChecked(false);

    //clears address autocomplete field
    document.getElementById('autocomplete').value = '';
  };


  const postSalesForceData = () => {
    console.log(formData)
    if(!formData.Product_Interest__c && !formData.Other_Product_Interest__c){
      Swal.fire({title: "Incomplete Form!", text: "Please choose a product.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
        })
    }
    else if(!formData.country){
      Swal.fire({title: "Incomplete Form!", text: "Please complete the Address field.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
          return false;
        })
    }
    else {
      let data = new FormData();
      let unixTimeStamp = Date.now()
      data.set("lead", JSON.stringify(formData));
      data.set("token", JSON.stringify(recaptchaToken));
      data.set("timeStamp", JSON.stringify(unixTimeStamp));
      console.log(data)

      axios
        .post("/lead", formData)
        .then((response) => {
          setShowLoading(false)
          //console.log(response);
          if (response.data.success) {
            Swal.fire({ title: "Success!", icon: "success", confirmButtonColor: '#002F87' });
            clearState();
          } else {
            Swal.fire({ title: "Oops!", icon: "error", confirmButtonColor: '#002F87' });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const validateFields = async () => {

    if(!formData.FirstName){
      Swal.fire({title: "Incomplete Form!", text: "Please complete the First Name field.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
          return false;
        })
    } else if(!formData.LastName){
      Swal.fire({title: "Incomplete Form!", text: "Please complete the Last Name field.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
          return false;
        })
    } else if(!formData.Company){
      Swal.fire({title: "Incomplete Form!", text: "Please complete the Company field.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
          return false;
        })
    }
    else if(!formData.Email){
      Swal.fire({title: "Incomplete Form!", text: "Please complete the Email field.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
          return false;
        })
    } else if(!formData.Phone){
      Swal.fire({title: "Incomplete Form!", text: "Please complete the Phone field.", icon: "error", confirmButtonColor: '#002F87'})
        .then(() => {
          setSubmitted(0);
          return false;
        })
    }
    else {
      return true;
    }
  }

  const onSubmit = async () => {
    let isFormComplete = await validateFields();
    if(isFormComplete){
      setShowLoading(true);
      const token = await reCaptchaRef.current.executeAsync();
      setRecaptchaToken(token);
      reCaptchaRef.current.reset();
      //console.log("submit");
      let checkedNames = [];

      //Creates list of Products in the format Salesforce needs.
      for (const [key, obj] of formData.ProductInterest) {
        if (obj.checked === 1) {
          //console.log(obj);
          checkedNames.push(obj.Name);
        }
      }
      let productInterest = checkedNames.join(", ");

      setFormData({
        ...formData,
        ...location,
        Product_Interest__c: productInterest,
        Other_Product_Interest__c: otherProducts
      });
    }
  };

  return (
    <div className='bg-light shadow-lg d-flex flex-column justify-content-between pb-4 pb-sm-0' style={{width: '100%', overflowY: "auto"}}>
      <br/>
      <div className='container-fluid d-flex flex-column justify-content-between'>
        <div className='row flex-column flex-md-row'>
          <section className='px-4 col formSection z-0'>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" placeholder="Sean" value={formData.FirstName} onChange={(e) => {setFormData({...formData, FirstName: e.target.value})}}/>
              <label htmlFor="floatingInput">*First Name</label>
            </div>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" placeholder="Bauman" value={formData.LastName} onChange={(e) => {setFormData({...formData, LastName: e.target.value})}}/>
              <label htmlFor="floatingInput">*Last Name</label>
            </div>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" placeholder="CEO" value={formData.Title} onChange={(e) => {setFormData({...formData, Title: e.target.value})}}/>
              <label htmlFor="floatingInput">Title</label>
            </div>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="floatingInput" placeholder="Immuno Mycologics" value={formData.Company} onChange={(e) => {setFormData({...formData, Company: e.target.value})}}/>
              <label htmlFor="floatingInput">*Facility/Organization</label>
            </div>
          </section>
          <section className='px-4 col formSection z-0'>
            <AutoComplete updateFormData={(addr, city, state, zip, country) => {setLocation({street: addr, city: city, state: state, postalCode: zip, country: country})}}/>
            <div className="form-floating mb-3">
              <input type="email" className="form-control" id="floatingInput" value={formData.Email} placeholder="Marketing@immy.com" onChange={(e) => {setFormData({...formData, Email: e.target.value})}}/>
              <label htmlFor="floatingInput">*Email</label>
            </div>
            <div className="form-floating mb-3">
              <input type="Phone" className="form-control" id="floatingInput" value={formData.Phone} placeholder="(405) 360-4669" onChange={(e) => {setFormData({...formData, Phone: e.target.value})}}/>
              <label htmlFor="floatingInput">*Phone</label>
            </div>
          </section>
        </div>
      </div>
      {/* Image Capture Modal End */}
      <div className='d-flex flex-column container-fluid'>
        <hr className='m-1'/>
        <h3 className='py-3 m-0'>Product Interests</h3>
        <div className='row px-3'>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('CrAg', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={cragChecked} aria-label="Checkbox for following text input" is="inlineRadio1"/>
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Cryptococcal Antigen (CrAg LFA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Asp', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={aspChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Aspergillus Galactomannan (LFA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Cocci LFA', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={cocciLFAChecked} aria-label="Checkbox for following text input" is="inlineRadio1"/>
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Coccidioides Antibody (LFA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Histo', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={histoChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Histoplasma Galactomannan (EIA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Crypto Antigen', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={cryptoAntigenChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Cryptococcal Antigen (EIA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Cocci Antibody', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={cocciAntibodyChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Coccidioides Antibody (EIA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Blasto Antibody', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={blastoAntibodyChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Blastomyces Antibody (EIA)</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Aspergillus EIA', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={aspEIAChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Aspergillus Galactomannan (EIA)</label>
              </div>
            </div>
          </section>

          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Latex', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={latexChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Latex Agglutination</label>
              </div>
            </div>
          </section>

          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('MycoDDR', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={mycoDDRChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>MycoDDR</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Immuno Reagents/Plates', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={immunoReagentsChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Immunodiffusion Reagents and Plates</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {productChange('Complement Fixation Reagents', e.target.checked)}}>
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={complementChecked} aria-label="Checkbox for following text input" is="inlineRadio1" />
                <div className="vr mx-2"></div>
                <label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Complement Fixation Reagents</label>
              </div>
            </div>
          </section>
          <section className='px-1 py-2 col-md-3 col-lg-2 z-0'>
            <div className="input-group h-100" onClick={(e) => {
              if(!otherChecked){
                productChange('Other', e.target.checked)}
            }}
            >
              <div className="input-group-text col-12 ProductTab">
                <input className="form-check-input mt-0" type="checkbox" checked={otherChecked} onClick={(e) => {
                  if(otherChecked){
                    productChange('Other', e.target.checked)}
                }}
                       aria-label="Checkbox for following text input"
                       is="inlineRadio1"
                />
                <div className="vr mx-2"></div>

                {otherChecked ?
                  <textarea className="form-control" placeholder="Other..." id="floatingTextarea" value={otherProducts} rows={1} onChange={(e) => {setOtherProducts(e.target.value)}} style={{resize: "none"}}></textarea>
                  : <><label className="form-check-label text-wrap flex-grow-1" htmlFor="inlineRadio1" style={{fontSize: "12px"}}>Other</label></>}
              </div>
            </div>
          </section>
        </div>
        <div className="form-floating">
          <textarea className="form-control mt-3" style={{height: '100px'}} placeholder="Add additional notes" id="floatingTextarea" value={formData.Notes__c} onChange={(e) => {setFormData({...formData, Notes__c: e.target.value})}}></textarea>
          <label htmlFor="floatingTextarea" style={{fontWeight: 'bold'}}>Notes</label>
        </div>
        <br/>
        <div className='footer col-12 mb-3 d-flex justify-content-start'>
          <button className="btn btn-primary mx-3" onClick={() => {setSubmitted(1);}}>Submit</button>
        </div>

      <br/>
      <ReCAPTCHA
        // secret key = '6LcWkTcpAAAAAI-K82LXLEDlCcU6LLhywSo3DTaz'|
        //sitekey = '6LcWkTcpAAAAANkxRqlH4i5klSuZVOKKBDEiinlb'
        // eslint-disable-next-line no-undef
        sitekey={process.env.REACT_APP_SITE_KEY}
        size="invisible"
        ref={reCaptchaRef}
      />
    </div>
    </div>
  )
}

export default Contact